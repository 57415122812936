import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../redux/slices/loaderSlice";
import { APIS } from "../api/api.constant";
import { postApi } from "../api/api.client";
import { TaskItems } from "../components/common/taskItems/TaskItems";
import NoDataFound from "../components/common/noDataFound";

const getDueValue = (inputDate) => {
  const [year, month, day] = inputDate.split("-");
  const currentDate = new Date(year, month - 1, day);
  const newDate = new Date(currentDate);
  const startDate = new Date(currentDate);
  newDate.setDate(newDate.getDate() + 7);
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const startMonthString = monthNames[startDate.getMonth()];
  const monthString = monthNames[newDate.getMonth()];
  const startformattedDate = `${startDate.getDate()}-${startMonthString}-${startDate.getFullYear()}`;
  const formattedDate = `${newDate.getDate()}-${monthString}-${newDate.getFullYear()}`;

  return `Due to ${startformattedDate} to ${formattedDate}`;
};

const WithTodoListHoc = (WrappedComponent) => {
  const NewWrappedComponent = (props) => {
    const [data, setData] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [apiType, setApiType] = useState(null);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const itemsPerPage = 2;

    const loadMoreData = () => {
      setTimeout(() => {
        const newData = data.slice(0, page * itemsPerPage);
        setActiveData(newData);

        setPage(page + 1);
      }, 700);
    };

    useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight
        )
          return;
        loadMoreData();
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [activeData]);

    useEffect(() => {
      loadMoreData();
    }, [data]);

    useEffect(() => {
      if (apiType !== null) {
        let payload = {};
        if (props?.byId?.idType === 1) {
          payload.student_id = props?.byId?.id;
        }
        if (props?.byId?.idType === 2) {
          payload.teacher_id = props?.byId?.id;
        }
        dispatch(toggleLoader({ loader: true }));
        postApi(APIS.TO_DO_LIST, {
          todo_type: apiType,
          page: 1,
          pageSize: 11,
          ...payload,
        })
          .then((res) => {
            setData(res?.payload?.data);
          })
          .catch((err) => {
            // console.log("err==>", err);
          })
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
          });
      }
    }, [apiType]);
    return (
      <>
        <div className="todo_list">
          {activeData?.map((item, i, arr) => {
            return (
              <Fragment key={i}>
                <p className="due_Date">{getDueValue(item?.task_start_date)}</p>
                {item?.tasks?.length > 0 &&
                  item?.tasks?.map((taskobj, index, taskarr) => {
                    return <TaskItems item={taskobj} key={i} />;
                  })}

                {item?.assignment?.assessment_document && (
                  <TaskItems item={item?.assignment} isAssignment={true} />
                )}
              </Fragment>
            );
          })}
        </div>
        {data?.length !== activeData?.length ||
          ((data?.length === 0 || activeData?.length === 0) && (
            <NoDataFound tableData={data} />
          ))}
        <WrappedComponent {...props} setApiType={setApiType} />
      </>
    );
  };

  return NewWrappedComponent;
};

export default WithTodoListHoc;
